<template>
    <div class="pay-c">
        <div class="pay-center">
            <div class="pay-center-top">
                <span>{{$t('upgrade.title')}}</span>
                <i @click="$store.state.upgradeShow = false" class="iconfont colse-icon-pay icon-guanbi"></i>
            </div>
            <transition name="el-zoom-in-center">
                <div class="pay-center-content" v-show="payOne">
                    <div class="pay-center-in-top">
                        <div @click="isUser(1)" :class="{userActivat: isUserActivat == 1}">{{$t('upgrade.send')}}</div>
                        <div @click="isUser(2)" :class="{userActivat: isUserActivat == 2}">{{$t('upgrade.user')}}</div>
                        <el-input v-if="isUserActivat == 2" class="pay-center-input" :placeholder="$t('upgrade.userName')" size="small" v-model="userName"></el-input>
                        <el-button v-if="isUserActivat == 2" style="margin-left:10px" size="small" type="primary">{{$t('upgrade.userSearch')}}</el-button>
                    </div>
                    <div class="pay-center-lr">
                        <div>
                            <div class="pay-center-lr-top">
                                <img :src="vip" alt="">
                                <span style="color:rgba(255,0,0,.4)">VIP</span>
                            </div>
                            <div class="pay-center-lr-text">
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span>{{$t('upgrade.text_01')}}</span>
                                </div>
                            </div>
                            <div class="pay-center-lr-jia">
                                <div @click="goMary(276)" :class="{maryActivat: form.price == 276}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>276USD/12{{$t('upgrade.text_03')}}</span>
                                    <span>23.0USD/{{$t('upgrade.text_03')}}</span>
                                    <span>{{$t('upgrade.text_02')}}23.3%</span>
                                </div>
                                <div @click="goMary(149)" :class="{maryActivat: form.price == 149}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>149USD/6{{$t('upgrade.text_03')}}</span>
                                    <span>24.9USD/{{$t('upgrade.text_03')}}</span>
                                    <span>{{$t('upgrade.text_02')}}17.2%</span>
                                </div>
                                <div @click="goMary(79)" :class="{maryActivat: form.price == 79}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>79USD/3{{$t('upgrade.text_03')}}</span>
                                    <span>26.4USD/{{$t('upgrade.text_03')}}</span>
                                    <span>{{$t('upgrade.text_02')}}12.2%</span>
                                </div>
                                <div @click="goMary(30)" :class="{maryActivat: form.price == 30}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>30USD/1{{$t('upgrade.text_03')}}</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="pay-center-lr-top">
                                <img :src="svip" alt="">
                                <span style="color:#f00">SVIP</span>
                            </div>
                            <div class="pay-center-lr-text">
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span>{{$t('upgrade.text_01')}}</span>
                                </div>
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span>{{$t('upgrade.text_01')}}</span>
                                </div>
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span> {{$t('upgrade.text_05')}}</span>
                                </div>
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span>{{$t('upgrade.text_06')}}</span>
                                </div>
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span>{{$t('upgrade.text_07')}}</span>
                                </div>
                                <div>
                                    <i class="iconfont icon-check"></i>
                                    <span>{{$t('upgrade.text_08')}}</span>
                                </div>
                            </div>
                            <div class="pay-center-lr-jia">
                                <div @click="goMary(999)" :class="{maryActivat: form.price == 999}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>999USD/12{{$t('upgrade.text_03')}}</span>
                                    <span>83.25USD/{{$t('upgrade.text_03')}}</span>
                                    <span>{{$t('upgrade.text_02')}}58.1%</span>
                                </div>
                                <div @click="goMary(799)" :class="{maryActivat: form.price == 799}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>799USD/6{{$t('upgrade.text_03')}}</span>
                                    <span>133.2USD/{{$t('upgrade.text_03')}}</span>
                                    <span>{{$t('upgrade.text_02')}}33.1%</span>
                                </div>
                                <div @click="goMary(499)" :class="{maryActivat: form.price == 499}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>499USD/3{{$t('upgrade.text_03')}}</span>
                                    <span>166.3USD/{{$t('upgrade.text_03')}}</span>
                                    <span>{{$t('upgrade.text_02')}}16.2%</span>
                                </div>
                                <div @click="goMary(199)" :class="{maryActivat: form.price == 199}">
                                    <i class="iconfont icon-Dollar-circle-fill"></i>
                                    <span>199USD/1{{$t('upgrade.text_03')}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pay-center-btn">
                        <div>
                            <div @click="goPayMary(5)" :class="{payActivat: form.payType == 5}"><img :src="pay_001" alt=""></div>
                            <div @click="goPayMary(3)" :class="{payActivat: form.payType == 3}"><img :src="pay_002" alt=""></div>
                        </div>
                        <el-button @click="goPayTwo" type="success">{{$t('pay.go')}}</el-button>
                    </div>
                </div>
            </transition>
            <transition name="el-zoom-in-center">
                <div v-show="payTwo" class="pay-center-content">
                    <img v-if="form.payType == 5" :src="pay_001" alt="">
                    <img v-if="form.payType == 3" :src="pay_002" alt="">
                    <div style="color:#999;font-size:14;margin:10px 0 20px 0">{{$t('pay.sstitle')}}：</div>
                    <div class="pay-center-two">
                        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                            <el-form-item :label="$t('sendUser.realName')" prop="name" style="margin-bottom:10px;width:50%">
                                <el-input size="small" style="small" v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('pay.call')" prop="phone" style="margin-bottom:10px;width:50%">
                                <el-input size="small" style="small" v-model="form.phone"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('pay.mailbox')" prop="email" style="width:50%">
                                <el-input size="small" style="small" v-model="form.email"></el-input>
                            </el-form-item>
                            
                            <el-form-item>
                                <el-button :disabled="btntrue" size="small" type="primary" @click="submitForm('ruleForm')">{{$t('pay.immediatelyPay')}}</el-button>
                                <el-button @click="goPayOne" size="small">{{$t('pay.goBack')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </transition>
        </div>
        <div class="text-center">{{payment_info}}</div>
    </div>
</template>

<script>
export default {
    data() {
        var checkPhone = (rule, value, callback) => {
            const phoneReg = /^(\+886\s)?[0]{1}[9]{1}\d{8}$/
                if (!value) {
                return callback(new Error(this.$i18n.t('pay.noCall')))
            }
            setTimeout(() => {
                if (!Number.isInteger(+value)) {
                    callback(new Error(this.$i18n.t('pay.noNumber')))
                } else {
                    if (phoneReg.test(value)) {
                        callback()
                    } else {
                        callback(new Error(this.$i18n.t('pay.noCallge')))
                    }
                }
            }, 100)
        }
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error(this.$i18n.t('pay.noMail')))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error(this.$i18n.t('pay.noMailge')))
                }
            }, 100)
        }
        return{
            btntrue: false,
            vip: require('../assets/images/vip.png'),
            svip: require('../assets/images/SVIP.png'),
            isUserActivat: 1,
            form: {
                payType: 5,
                orderType: 2,
                language: "english",
                price: 1299,
                monthCount: 12,
                name: "",
                phone: "",
                email: "",
                userId: "",
                nickName: "",
                guid:"",
                ip:"",
            },
            rules: {
                name: [
                    { required: true, message: this.$i18n.t('pay.noName'), trigger: 'blur' },
                    { min: 2, max: 32, message: this.$i18n.t('pay.noNameNumber'), trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: this.$i18n.t('pay.noCall'), trigger: 'blur' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                email: [
                    { required: true, message: this.$i18n.t('pay.noMail'), trigger: 'blur' },
                    { validator: checkEmail, trigger: 'blur' }
                ]
            },
            payOne: true,
            payTwo: false,
            isPayActivat: 5,
            ziDingYiShow: false,
            money: 0,
            zidingyi: this.$i18n.t('pay.custom'),
            pay_001: require("../assets/images/pay_001.png"),
            pay_002: require("../assets/images/pay_002.png"),
            payment_info: ""
        }
    },
    created(){
        this.form.userId = JSON.parse(sessionStorage.getItem('sendForm')).userId
        this.form.nickName = JSON.parse(sessionStorage.getItem('sendForm')).nickName
        this.getIpAndGuid()
    },
    mounted(){
        this.payment_info = payment_info
    },
    methods: {
        getIpAndGuid(){
            this.$isAxios.axiosGet(this.$isApi.getIpAndGuid,'',(res)=>{
                if(res.data.message == 'success'){
                    this.form.guid = res.data.result.guid,
                        this.form.ip = res.data.result.ip
                    let script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = 'https://www.wshtmltool.com/order_bill.js?guid='+this.form.guid+'&language=en-us&ip='+this.form.guid+'&url_referrer_domain=www.kisssoul.com' ;
                    // console.log(333+'https://www.wshtmltool.com/order_bill.js?guid='+this.form.guid+'&language=en-us&ip='+this.form.ip+'&url_referrer_domain=www.kisssoul.com')
                    document.body.appendChild(script);
                }
            })
        },
        isUser(num){
            this.isUserActivat = num
        },
        goMary(number){
            this.form.price = number
            if(number == 1299 || number == 749){
                this.form.monthCount = 12
            }else if(number == 899 || number == 449){
                this.form.monthCount = 6
            }else if(number == 499 || number == 249){
                this.form.monthCount = 3
            }else{
                this.form.monthCount = 1
            }
        },
        goPayOne(){
            this.payTwo = false
            setTimeout(()=>{
                this.payOne = true
            },200)
        },
        goPayMary(num){
            this.form.payType = num
        },
        goPayTwo(){
            this.payOne = false
            setTimeout(()=>{
                this.payTwo = true
            },200)
        },
        goPayOne(){
            this.payTwo = false
            setTimeout(()=>{
                this.payOne = true
            },200)
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.btntrue = true
                    this.$isAxios.axiosPost(this.$isApi.commitOrder,this.form,(res)=>{
                        if(res.data.message == 'success'){
                            if(res.data.result.orderRecord.status == 0){
                                this.$message.error(res.data.result.orderRecord.message)
                                return false
                            }else{
                                window.open(res.data.result.paymentUrl,'_blank')
                            }
                        }
                    })
                } else {
                    return false;
                }
            })
        }
    }
}
</script>

<style scoped>
.pay-c{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.pay-center{
    width: 50vw;
    height: max-content;
    background: #fff;
    border-radius: 4px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .2);
    overflow: hidden;
}
.pay-center-top{
    height: 48px;
    padding: 0 20px;
    background: #fd463e;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
}
.pay-center-content{
    padding: 20px;
}
.pay-center-in-top{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pay-center-in-top>div:nth-child(1),
.pay-center-in-top>div:nth-child(2){
    font-size: 14px;
    color: #666;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    padding: 0 20px;
}
.pay-center-input{
    width: 200px;
}
.userActivat{
    border: 1px solid #f00 !important;
    color: #f00 !important;
}
.pay-center-lr{
    height: max-content;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.pay-center-lr>div{
    width: calc(48% - 40px );
    height: max-content;
    background: rgba(255, 0, 0, .04);
    border-radius: 4px;
    padding: 20px;
}
.pay-center-lr-top{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pay-center-lr-top>img{
    width: 52px;
    height: 40px;
}
.pay-center-lr-top>span{
    font-size: 22px;
    font-weight: bold;
    margin-left: 15px;
}
.pay-center-lr-text{
    display: flex;
    flex-wrap: wrap;
    padding-left: 4px;
    margin-top: 10px;
    height: 90px;
}
.pay-center-lr-text>div{
    width: 120px;
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pay-center-lr-text>div>i{
    font-size: 22px;
    color: #f00;
}
.pay-center-lr-text>div>span{
    font-size: 12px;
    color: #999;
    margin-left: 10px;
}
.pay-center-lr-jia{
    height: max-content;
    margin-top: 20px;
}
.pay-center-lr-jia>div{
    height: 30px;
    background: rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 6px;
    border-radius: 4px;
    cursor: pointer;
}
.pay-center-lr-jia>div>i{
    font-size: 18px;
    color: #f00;
    margin-right: 10px
}
.pay-center-lr-jia>div>span{
    display: block;
    font-size: 12px;
    flex: 1
}
.pay-center-lr-jia>div:hover{
    color: #fff;
    background: #f00;
}
.pay-center-lr-jia>div:hover>i{
    color: #fff;
}
.maryActivat{
    color: #fff !important;
    background: #f00 !important;
}
.maryActivat>i{
    color: #fff !important;
}
.pay-center-btn{
    display: flex;
    height: max-content;
    align-items: flex-end !important;
    margin-top: 16px;
}
.pay-center-btn>div:nth-child(1){
    display: flex;
    flex: 1
}
.pay-center-btn>div:nth-child(1)>div{
    width: 180px;
    height: 60px;
    background: #fff;
    margin-right: 20px;
    text-align: center;
    line-height: 60px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pay-center-btn>div:nth-child(1)>div>img{
    object-fit: scale-down;
}
.colse-icon-pay{
    cursor: pointer;
    color: rgba(255, 255, 255, .6);
}
.colse-icon-pay:hover{
    color: #fff;
}
.payActivat{
    background: rgba(255, 0, 0, .04) !important;
    border: 1px solid #f00 !important;
}
</style>