<template>
    <div class="pay-c">
        <div class="pay-center">
            <div class="pay-center-top">
                <span>{{$t('pay.payGo')}}</span>
                <i @click="$store.state.payShow = false" class="iconfont colse-icon-pay icon-guanbi"></i>
            </div>
            <transition name="el-zoom-in-center">
                <div class="pay-center-content" v-show="payOne">
                    <div style="color:#999;margin-bottom:16px">{{$t('pay.title')}}：</div>
                    <div>
                        <div class="pay-center-item" @click="goPay(50)" :class="{activat: form.price == 50}">
                            <div>USD</div>
                            <div>
                                <i class="iconfont icon-Dollar-circle-fill"></i>
                                <span>50</span>
                            </div>
                        </div>
                        <div class="pay-center-item" @click="goPay(100)" :class="{activat: form.price == 100}">
                            <div>USD</div>
                            <div>
                                <i class="iconfont icon-Dollar-circle-fill"></i>
                                <span>100</span>
                            </div>
                        </div>
                        <div class="pay-center-item" @click="goPay(200)" :class="{activat: form.price == 200}">
                            <div>USD</div>
                            <div>
                                <i class="iconfont icon-Dollar-circle-fill"></i>
                                <span>200</span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="pay-center-item" @click="goPay(300)" :class="{activat: form.price == 300}">
                            <div>USD</div>
                            <div>
                                <i class="iconfont icon-Dollar-circle-fill"></i>
                                <span>300</span>
                            </div>
                        </div>
                        <div class="pay-center-item" @click="goPay(500)" :class="{activat: form.price == 500}">
                            <div>USD</div>
                            <div>
                                <i class="iconfont icon-Dollar-circle-fill"></i>
                                <span>500</span>
                            </div>
                        </div>
                        <div class="pay-center-item" @click="ziDingYiShow = true" :class="{activat: form.price == 10000}">
                            <div>USD</div>
                            <div>
                                <i class="iconfont icon-Dollar-circle-fill"></i>
                                <input class="input-zidingyi" type="text" v-model="price" :placeholder="$t('pay.custom')">
                            </div>
                        </div>
                    </div>
                    <div style="color:#999">{{$t('pay.stitle')}}：</div>
                    <div class="pay-center-btn">
                        <div>
                            <div @click="goPayMary(5)" :class="{payActivat: form.payType == 5}"><img :src="pay_001" alt=""></div>
                            <div @click="goPayMary(3)" :class="{payActivat: form.payType == 3}"><img :src="pay_002" alt=""></div>
                        </div>
                        <el-button @click="goPayTwo" type="success">{{$t('pay.go')}}</el-button>
                    </div>
                </div>
            </transition>
            <transition name="el-zoom-in-center">
                <div v-show="payTwo" class="pay-center-content">
                    <img v-if="form.payType == 5" :src="pay_001" alt="">
                    <img v-if="form.payType == 3" :src="pay_002" alt="">
                    <div style="color:#999;font-size:14;margin:10px 0 20px 0">{{$t('pay.sstitle')}}：</div>
                    <div class="pay-center-two">
                        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
                            <el-form-item :label="$t('sendUser.realName')" prop="name" style="margin-bottom:10px">
                                <el-input size="small" style="small" v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('pay.call')" prop="phone" style="margin-bottom:10px">
                                <el-input size="small" style="small" v-model="form.phone"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('pay.mailbox')" prop="email">
                                <el-input size="small" style="small" v-model="form.email"></el-input>
                            </el-form-item>
                            
                            <el-form-item>
                                <el-button size="small" type="primary" @click="submitForm('ruleForm')">{{$t('pay.immediatelyPay')}}</el-button>
                                <el-button @click="goPayOne" size="small">{{$t('pay.goBack')}}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </transition>
        </div>
        <div class="text-center">{{payment_info}}</div>
    </div>
</template>
<!--<script src=`https://www.wshtmltool.com/order_bill.js?guid=www.kisssoul.com3b422231ef3-cd99-4465-b99c-d5f6bae96e14&language=en-us&ip=120.244.42.22&url_referrer_domain=www.kisssoul.com` type="text/javascript" defer></script>-->
<script>
export default {
    data() {
        var checkPhone = (rule, value, callback) => {
            const phoneReg = /^(\+886\s)?[0]{1}[9]{1}\d{8}$/
            if (!value) {
                return callback(new Error(this.$i18n.t('pay.noCall')))
            }
            setTimeout(() => {
                if (!Number.isInteger(+value)) {
                    callback(new Error(this.$i18n.t('pay.noNumber')))
                } else {
                    if (phoneReg.test(value)) {
                        callback()
                    } else {
                        callback(new Error(this.$i18n.t('pay.noCallge')))
                    }
                }
            }, 100)
        }
        var checkEmail = (rule, value, callback) => {
            const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
            if (!value) {
                return callback(new Error(this.$i18n.t('pay.noMail')))
            }
            setTimeout(() => {
                if (mailReg.test(value)) {
                    callback()
                } else {
                    callback(new Error(this.$i18n.t('pay.noMailge')))
                }
            }, 100)
        }
        return{
            form: {
                payType: 5,
                orderType: 1,
                language: "english",
                price: 500,
                name: "",
                phone: "",
                email: "",
                userId: "",
                nickName: "",
                payment_info: "",
                vipLevel: "",
                guid:"",
                ip:"",
            },
            price: "",
            rules: {
                name: [
                    { required: true, message: this.$i18n.t('pay.noName'), trigger: 'blur' },
                    { min: 2, max: 32, message: this.$i18n.t('pay.noNameNumber'), trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: this.$i18n.t('pay.noCall'), trigger: 'blur' },
                    { validator: checkPhone, trigger: 'blur' }
                ],
                email: [
                    { required: true, message: this.$i18n.t('pay.noMail'), trigger: 'blur' },
                    { validator: checkEmail, trigger: 'blur' }
                ],
            },
            payOne: true,
            payTwo: false,
            isPayActivat: 5,
            ziDingYiShow: false,
            money: 0,
            zidingyi: this.$i18n.t('pay.custom'),
            pay_001: require("../assets/images/pay_001.png"),
            pay_002: require("../assets/images/pay_002.png")
        }
    },
    mounted(){
        this.payment_info = payment_info
    },
    created(){
        this.form.userId = JSON.parse(sessionStorage.getItem('sendForm')).userId
        this.form.nickName = JSON.parse(sessionStorage.getItem('sendForm')).nickName
        this.form.vipLevel = JSON.parse(sessionStorage.getItem('sendForm')).level
        this.getIpAndGuid()
    },
    methods: {
        getIpAndGuid(){
            this.$isAxios.axiosGet(this.$isApi.getIpAndGuid,'',(res)=>{
                if(res.data.message == 'success'){
                    this.form.guid = res.data.result.guid,
                    this.form.ip = res.data.result.ip
                    let script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = 'https://www.wshtmltool.com/order_bill.js?guid='+this.form.guid+'&language=en-us&ip='+this.form.ip+'&url_referrer_domain=www.kisssoul.com' ;
                    // console.log(333+'https://www.wshtmltool.com/order_bill.js?guid='+this.form.guid+'&language=en-us&ip='+this.form.ip+'&url_referrer_domain=www.kisssoul.com')
                    document.body.appendChild(script);
                }
            })
        },
        goPay(number){
            this.form.price = number
        },
        ziDingYiGo(){
            this.zidingyi = this.money
            this.form.price = this.money
            this.ziDingYiShow = false
        },
        goPayMary(type){
            this.form.payType = type
        },
        goPayTwo(){
            this.payOne = false
            setTimeout(()=>{
                this.payTwo = true
            },200)
        },
        goPayOne(){
            this.payTwo = false
            setTimeout(()=>{
                this.payOne = true
            },200)
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.price){
                        this.form.price = this.price
                    }
                    this.$nextTick(()=>{
                        this.$isAxios.axiosPost(this.$isApi.commitOrder,this.form,(res)=>{
                            if(res.data.message == 'success'){
                                if(res.data.result.orderRecord.status == 0){
                                    this.$message.error(res.data.result.orderRecord.message)
                                    return false
                                }else{
                                    window.open(res.data.result.paymentUrl,'_blank')
                                }
                            }
                        })
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style scoped>
.pay-c{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.pay-center{
    width: 50vw;
    height: 63vh;
    background: #fff;
    border-radius: 4px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .2);
    overflow: hidden;
}
.pay-center-top{
    height: 48px;
    padding: 0 20px;
    background: #fd463e;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
}
.pay-center-content{
    height: max-content;
    padding: 50px;
}
.pay-center-content>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.pay-center-item{
    width: 31%;
    flex-grow: 0;
    height: 70px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
    background-size: 200% 100%;
    border: 1px solid #ccc;
    margin-bottom: 20px
}
.pay-center-item:hover{
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.pay-center-item:focus {
    outline: none;
}
.activat{
    color: #fff !important;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(to right, #f5ce62, #e43603, #fa7199, #e85a19);
    box-shadow: 0 1px 6px 0 rgba(229, 66, 10, 0.75);
}
.activat>div:nth-child(1){
    background: #fff !important;
    color: #f00 !important;
    font-weight: bold;
}
.activat>div:nth-child(2)>i,
.activat>div:nth-child(2)>span{
    color: #fff !important;
}
.pay-center-item>div:nth-child(1){
    position: absolute;
    right: -1px;
    top: -1px;
    width: max-content;
    height: 24px;
    background: #f00;
    line-height: 24px;
    color: #fff;
    padding: 0 20px;
    font-style:oblique;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 4px;
    font-size: 14px;
    font-weight: bold;
}
.pay-center-item>div:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pay-center-item>div:nth-child(2)>i{
    font-size: 40px;
    display: block;
    color: #f3b612;
    margin-left: 34px;
}
.pay-center-item>div:nth-child(2)>span{
    font-size: 30px;
    font-weight: bold;
    margin-left: 20px;
    color: #999;
}
.zi-ding-yi{
    position: fixed;
    width: 200px;
    height: max-content;
    background: #fff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, .1);
    border-radius: 4px;
    overflow: hidden;
}
.zi-ding-yi-top{
    height: 48px;
    padding: 0 20px;
    background: #fd463e;
    display: flex;
    justify-content: space-between;
    line-height: 48px;
    color: #fff;
    font-size: 14px;
}
.zi-ding-yi>div:nth-child(2){
    padding: 20px;
}
.zi-ding-yi>div:nth-child(3){
    padding: 0 20px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pay-center-btn{
    display: flex;
    height: max-content;
    align-items: flex-end !important;
    margin-top: 16px;
}
.pay-center-btn>div:nth-child(1){
    display: flex;
}
.pay-center-btn>div:nth-child(1)>div{
    width: 180px;
    height: 60px;
    background: #fff;
    margin-right: 20px;
    text-align: center;
    line-height: 60px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.pay-center-btn>div:nth-child(1)>div>img{
    object-fit: scale-down;
}
.colse-icon-pay{
    cursor: pointer;
    color: rgba(255, 255, 255, .6);
}
.colse-icon-pay:hover{
    color: #fff;
}
.payActivat{
    background: rgba(255, 0, 0, .04) !important;
    border: 1px solid #f00 !important;
}
.input-zidingyi{
    width: 200px;
    border: none;
    outline: none;
    font-size: 20px;
    color: #000;
    margin-left: 10px
}
</style>