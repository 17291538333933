<template>
    <div class="home-gift">
        <div class="title-text">
            <div>
                <i class="iconfont icon-shoucang"></i>
                <span>{{$t('love.name')}}</span>
            </div>
        </div>
        <div v-if="$store.state.loveGift.length > 0" class="home-gift-list" v-for="(item,index) in $store.state.loveGift.slice(0,1)" :key="index">
            <div class="home-item">
                <img :src="$isApi.http + item.header" alt="" @click="goUserForm(item)">
                <div class="home-item-bottom">
                    <div>
                        <span>{{item.nickName}}</span>
                        <i v-if="item.sex == 'women'" style="color:#FE9ED4" class="iconfont icon-xingbienv1"></i>
                        <i v-else style="color:#6798FF" class="iconfont icon-xingbienan1"></i>
                    </div>
                    <div v-if="item.marriageStatus || item.weight || item.height || item.education || item.occupation">
                        <span v-if="item.marriageStatus">{{item.marriageStatus}}</span>
                        <span v-if="item.weight">{{item.weight}}KG</span>
                        <span v-if="item.height">{{item.height}}CM</span>
                        <span v-if="item.education">{{item.education}}</span>
                        <span v-if="item.occupation">{{item.occupation}}</span>
                    </div>
                    <div>{{item.signature}}</div>
                </div>
                <div class="home-item-love">
                    <el-tooltip class="item" effect="dark" :content="$t('grilList.btnLove')" placement="top">
                        <div @click="liveUser(item,index,'yes')" class="iconfont icon-shoucang"></div>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="$t('love.noLove')" placement="top">
                         <div @click="liveUser(item,index,'no')" class="iconfont icon-guanbi"></div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div v-if="$store.state.loveGift.length == 0" class="home-gift-show">{{$t('love.noText')}}</div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            sendForm: {}
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
    },
    methods: {
        liveUser(item,index,type){
            if(type == 'yes'){
                var json = {
                    userId: this.sendForm.userId,
                    beLikedId: item.userId,
                    isRead: false
                }
                this.$isAxios.axiosPost(this.$isApi.addMyLike,json,(res)=>{
                    if(res.data.message == 'success'){
                        this.$message({
                            message: this.$i18n.t('grilList.addSuccess'),
                            type: 'success'
                        })
                    }
                })
            }
            this.$nextTick(()=>{
                this.$store.state.loveGift.splice(index, 1)
            })
        },
        goUserForm(item){
            sessionStorage['userId'] = item.userId
            this.$nextTick(()=>{
                this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:item.userId,visitorId:this.sendForm.userId},(fang)=>{
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                })
            })
        }
    }
}
</script>

<style scoped>
.home-gift{
    height: max-content;
    background: #fff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
    padding: 15px;
    margin-bottom: 18px;
}
.home-gift-list{
    height: max-content;
}
.home-gift-show{
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #ccc;
}
.home-item{
    height: 320px;
    background: #f00;
    position: relative;
    margin-top: 6px;
}
.home-item>img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
    cursor: pointer;
}
.home-item-bottom{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 58px;
    background: rgba(0, 0, 0, .6);
    padding: 20px 16px 10px 16px;
}
.home-item-bottom>div:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
}
.home-item-bottom>div:nth-child(1)>span{
    font-size: 16px;
    height: 16px;
    line-height: 16px
}
.home-item-bottom>div:nth-child(1)>i{
    font-size: 18px;
    height: 18px;
    line-height: 18px;
    margin-left: 6px;
    font-weight: bold;
    display: block;
    padding-top: 2px
}
.home-item-bottom>div:nth-child(2){
    height: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 6px;
}
.home-item-bottom>div:nth-child(2)>span{
    font-size: 12px;
    height: 12px;
    line-height: 12px;
    color: rgba(255, 255, 255, .6);
    display: block;
    margin-right: 5px;
    border-right: 1px solid rgba(255, 255, 255, .6);
    padding-right: 5px
}
.home-item-bottom>div:nth-child(2)>span:last-child{
    border: none
}
.home-item-bottom>div:nth-child(3){
    font-size: 13px;
    color: rgba(255, 255, 255, .8);
    margin-top: 4px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.home-item-love{
    height: 40px;
    width: max-content;
    position: absolute;
    bottom: 70px;
    right: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.home-item-love>div:nth-child(1){
    width: 40px;
    height: 40px;
    background-image:-webkit-linear-gradient(135deg,#e400ff, #ffcc00);
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    font-size: 22px;
    margin-left: 16px;
    cursor: pointer;
    color: #fff;
}
.home-item-love>div:nth-child(1):hover{
    background-image:-webkit-linear-gradient(135deg,#ffcc00, #e400ff);
}
.home-item-love>div:nth-child(2){
    width: 40px;
    height: 40px;
    background: #fff;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
    color: #ccc;
}
.home-item-love>div:nth-child(2):hover{
    color: #000;
    background: #fff
}
</style>