<template>
    <div class="page-fixed">
        <el-tooltip class="item" effect="dark" :content="$t('grilList.btnChat')" placement="left">
            <div @click="sayPlay" class="iconfont icon-liaotian pocc-icon" v-if="!$store.state.sayShow">
                <div class="podd-num" v-if="$store.state.sayNumber > 0">{{$store.state.sayNumber}}</div>
            </div>
            <div @click="sayPlay" v-else class="iconfont icon-liaotian pocc-icon"></div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('pageFixed.service')" placement="left">
            <div @click="goKefu('2c988fde790e55f801791232ef9b0117')" class="iconfont icon-kefu"></div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('pageFixed.recharge')" placement="left">
            <div @click="$store.state.payShow = true" class="iconfont icon-Dollar-circle-fill"></div>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :content="$t('pageFixed.upgrade')" placement="left">
            <div @click="$store.state.upgradeShow = true" class="iconfont icon-jewelry"></div>
        </el-tooltip>
    </div>
</template>

<script>
import { setTimeout } from 'timers';
export default {
    data(){
        return{
            sendForm: {}
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))  //我的信息
    },
    methods: {
        goKefu(id){
            this.$isChat.newChat(this,{userId:id})
        },
        sayPlay(){
            this.$isChat.chatList(JSON.parse(sessionStorage['sendForm']),(res)=>{
                if(res.result.length > 0){
                    this.$store.state.chatForm = res.result[0].receiveUser
                    this.$nextTick(()=>{
                        var json = {
                            sendId: JSON.parse(sessionStorage['sendForm']).userId,
                            receiveId: res.result[0].receiveUser.userId
                        }
                        this.$isAxios.axiosGet(this.$isApi.chattingInfo,json,(res) => {
                            this.$store.state.sayNumber = 0
                            this.$store.state.sayShow = true
                        })
                    })
                }else{
                    this.$message(this.$i18n.t('pageFixed.noChat'))
                }
            })
        }
    }
}
</script>

<style scoped>
.page-fixed{
    position: fixed;
    top: 530px;
    right: 100px;
    height: max-content;
    width: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: column;
}
.page-fixed>div{
    width: 40px;
    height: 40px;
    background-image: linear-gradient(135deg, #ffcc00, #e400ff);
    border-radius: 8px;
    line-height: 40px;
    text-align: center;
    color: #FFF;
    font-size: 26px;
    cursor: pointer;
    margin-bottom: 6px;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .2);
    transition: all 0.1s ease-in;
}
.page-fixed>div:hover{
    background-image: linear-gradient(135deg, #e400ff, #ffcc00);
}
.pocc-icon{
    position: relative;
}
.podd-num{
    position: absolute;
    left: -8px;
    top: -5px;
    background: #f00;
    width: max-content;
    height: 16px;
    line-height: 16px;
    padding: 0 5px;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    font-size: 12px;
    font-weight: bold
}
</style>
