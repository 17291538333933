<template>
    <div class="page-home">
        <div class="page-home-center">
            <img class="page-home-logo" :src="url" alt="">
            <div class="page-home-nav">
                <router-link tag='div' to="/page/home">
                    <i class="iconfont icon-Moneymanagement"></i>
                    <span>{{$t('nav.home')}}</span>
                </router-link>
                <router-link tag='div' to="/page/love">
                    <i class="iconfont icon-kehuquanyi"></i>
                    <span>{{$t('nav.online')}}</span>
                </router-link>
                <router-link tag='div' to="/page/loveWall">
                    <i class="iconfont icon-quanqiukuajing"></i>
                    <span>{{$t('nav.act')}}</span>
                </router-link>
            </div>
            <div class="page-home-user">
                <img class="page-home-user-logo" v-if="sendForm.header" :src="this.$isApi.http + sendForm.header" alt="">
                <div class="page-home-user-c">
                    <el-dropdown>
                        <span>{{sendForm.nickName}}</span>
                        <i class="iconfont icon-jiantouxia"></i>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <div @click="goUrl('/page/user/information')">{{$t('pageHeader.myHome')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="goUrl('/page/user/details')">{{$t('pageHeader.dataModification')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="goUrl('/page/user/password')">{{$t('pageHeader.password')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item divided>
                                <div @click="goLogin">{{$t('pageHeader.out')}}</div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="page-home-user-d">
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            {{langItem}}<i class="el-icon-arrow-down el-icon--right"></i>(Language)
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item  v-for="(item,index) in langList" :key="index">
                                <span style="display:block" @click="langChange(item)">{{item.name}}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setTimeout } from 'timers'
export default {
    data() {
        return{
            url: require("../assets/images/logo.png"),
            langShow: true,
            langItem: "繁体中文",
            langList: [{
                name: "简体中文",
                value: "zh-cn"
            },{
                name: "繁体中文",
                value: "zh-tw"
            },{
                name: "English",
                value: "en-us"
            }],
            sendForm: {}
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage['sendForm'])
        if(sessionStorage.lang){
            this.$i18n.locale = sessionStorage.lang
            this.langList.forEach( item => {
                if(item.value == sessionStorage.lang){
                    this.langItem = item.name
                }
            })
        }else{
            this.$i18n.locale = 'zh-tw'
            this.langItem = "繁体中文"
        }
    },
    methods: {
        langChange(item){
            this.langItem = item.name
            this.$i18n.locale = item.value
            sessionStorage['lang'] = item.value
        },
        goUrl(url){
            this.$router.push({
                path: url
            })
        },
        goLogin(){
            sessionStorage.clear()
            this.$store.state.chatArrForm = []
            this.$store.state.chatForm = {}
            this.$store.state.setContent = {}
            this.$store.state.getContent = {}
            this.$nextTick(()=>{
                window.location.reload()
                this.$router.push({
                    path: "/"
                })
            })
        }
    }
}
</script>

<style scoped>
.page-home{
    height: 60px;
    background: #FD463E;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 96;
}
.page-home-center{
    width: 1200px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.page-home-logo{
    width: 200px;
    height: 44px;
    object-fit: scale-down
}
.page-home-nav{
    display: flex;
    width: max-content;
    flex: 1;
    margin-left: 2vw
}
.page-home-nav>div{
    width: max-content;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    height: 60px;
}
.page-home-nav>div:hover{
    background: #e60212;
}
.page-home-nav>div>i,
.page-home-nav>div>span{
    display: block;
    color: #fff;
}
.page-home-nav>div>i{
    font-size: 28px;
    margin-right: 4px
}
.page-home-nav>div>span{
    font-size: 14px;
}
.page-home-user{
    display: flex;
    font-size: 14px;
    color: #fff;
    flex-direction: row;
    align-items: center;
}
.page-home-user>img{
    widows: 40px;
    height: 40px;
    background: #eee;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 6px
}
.page-home-user-c{
    margin-right: 10px;
    cursor: pointer;
}
.page-home-user-d{
    cursor: pointer;
}
.router-link-exact-active{
    background: #e60212
}
</style>