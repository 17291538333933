<template>
    <div class="love-wall">
        <div class="title-text">
            <div>
                <i class="iconfont icon-shoucang"></i>
                <span>{{$t('newState.name')}}</span>
            </div>
            <span class="more" @click="goLoveWall">{{$t('newState.more')}}</span>
        </div>
        <div class="love-list">
            <div class="love-list-item" v-for="(item,index) in stateList.slice(0,3)" :key="index">
                <div @click="goUserXQ(item.userId)">
                    <img :src="$isApi.http + item.header" alt="">
                </div>
                <div>
                    <div class="love-list-name">
                        <span>{{item.nickName}}</span>
                        <i v-if="item.sex == 'women'" style="color:#FE9ED4" class="iconfont icon-xingbienv1"></i>
                        <i v-else style="color:#6798FF" class="iconfont icon-xingbienan1"></i>
                    </div>
                    <div class="live-list-di">
                        <div v-if="item.age || item.area">
                            <span v-if="item.age">{{item.age}}岁</span>
                            <span v-if="item.area" style="margin-left: 10px">{{item.area}}</span>
                        </div>
<!--                        <div>{{$isPulic.timestampToTime(item.createDate)}}</div>-->
                    </div>
                    <div class="live-list-say">{{item.content}}</div>
                    <div class="live-list-imgs" v-if="item.fileUrl instanceof Array">
                        <el-image 
                            fit="contain"
                            class="live-list-imgss"
                            v-for="(img,index) in item.fileUrl" 
                            :key="index" 
                            :src="img"
                            :preview-src-list="item.fileUrl">
                        </el-image>
                    </div>
                    <div class="state-center-imgs" v-else-if="item.fileUrl.IsPicture('.jpg') || item.fileUrl.IsPicture('.png') || item.fileUrl.IsPicture('.gif') || item.fileUrl.IsPicture('.jpeg')">
                        <el-image 
                            fit="contain"
                            class="live-list-imgss"
                            :src="item.fileUrl"
                            :preview-src-list="[item.fileUrl]">
                        </el-image>
                    </div>
                    <div v-else-if="item.type == 'video'">
                        <div @click="videoChange" v-if="sendForm.level == 0" class="iconfont icon-icon_play video-icon"></div>
                        <video 
                            v-else
                            :src="$isApi.http + item.fileUrl"
                            class="video-avatar"
                            controls="controls" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            sendForm: {},
            stateList: []
        }
    },
    created(){
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))
        this.$isAxios.axiosGet(this.$isApi.showAllDynamics,{pageNo:1,pageSize:4},(res)=>{
            if(res.data.result){
                res.data.result.forEach( item => {
                    if(item.fileUrl){
                        if(item.fileUrl.indexOf(",") != -1){
                            item.fileUrl = item.fileUrl.split( ',' )
                        }
                    }
                })
                this.total = res.data.total
                this.stateList = res.data.result
            }
        })
    },
    methods: {
        videoChange(){
            this.$confirm(this.$i18n.t('login.shenVipState1'), this.$i18n.t('giftComponents.reminder'), {
                confirmButtonText: this.$i18n.t('userLeft.success'),
                cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                type: 'warning'
            }).then(() => {
                this.$store.state.upgradeShow = true
            })
        },
        goUserXQ(id){
            sessionStorage['userId'] = id
            if(id == this.sendForm.userId){
                this.$router.push({
                    path: "/page/userXQ",
                })
            }else{
                this.$isAxios.axiosPost(this.$isApi.addVisitor,{userId:id,visitorId:this.sendForm.userId},(fang)=>{
                    this.$router.push({
                        path: "/page/userXQ",
                    })
                })
            }
        },
        goLoveWall(){
            this.$router.push({
                path: "/page/loveWall"
            })
        }
    }
}
</script>

<style scoped>
.video-icon{
    width: 140px;
    height: 100px;
    background: #000;
    text-align: center;
    line-height: 100px;
    color: #fff;
    font-size: 24px;
    margin-top: 14px;
    cursor: pointer;
}
.love-wall{
    height: max-content;
    background: #fff;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, .1);
    padding: 15px;
    margin-bottom: 18px;
}
.love-list{
    height: max-content;
    margin-top: 10px
}
</style>

